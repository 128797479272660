export const bagetArray = [
        [20, 218, 287, 362, 455, 596, false, false, 25, 40, 10, 55],
        [25, 253, 330, 413, 516, 668, false, false, 30, 65, 15, 60],
        [30, 270, 348, 432, 579, 687, 908, 1155, 35, 70, 20, 65],
        [35, 305, 390, 481, 594, 755, 995, 1257, 40, 75, 25, 70],
        [40, 342, 434, 532, 654, 826, 1085, 1363, 45, 80, 30, 80],
        [45, 379, 478,     584,  714,  896, 1174, 1468,  50,  85,  35, 90 ],
        [50, 394, 492,     605,  732,  918, 1199, 1491,  55,  90,  40, 100 ],
        [55, 419, 524,     634,  769,  955, 1242, 1539,  60, 100,  45, 150 ],
        [60, 457, 568,     685,  828, 1023, 1327, 1639,  65, 120,  50, 200 ],
        [65, 495, 613,     736,  887, 1092, 1413, 1739,  70, 140,  55, 250 ],
        [70, 535, 659,     789,  948, 1162, 1500, 1841,  75, 160,  60, 300 ],
        [75, 576, 706,     842, 1009, 1233, 1588, 1943,  80, 180,  65, 350 ],
        [80, 618, 755,     897, 1073, 1306, 1678, 2047,  85, 200,  70, 400 ],
        [85, 660, 804,     952, 1136, 1378, 1768, 2152,  90, 220,  75, 450 ],
        [90, 702, 852,    1008, 1199, 1451, 1857, 2256,  95, 240,  80, 500 ],
        [95, 745, 901,    1063, 1262, 1524, 1947, 2360, 100, 260,  85, 550 ],
        [100, 800, 963,   1132, 1339, 1610, 2050, 2478, 105, 280,  90, 800 ],
        [105, 853, 1023,  1197, 1413, 1693, 2151, 2593, 110, 300,  95, 850 ],
        [110, 906, 1082,  1263, 1486, 1776, 2251, 2707, 115, 320, 100, 900 ],
        [115, 958, 1141,  1328, 1560, 1860, 2351, 2822, 120, 340, 105, 950 ],
        [120, 1011, 1200, 1394, 1634, 1943, 2451, 2936, 125, 360, 110, 1000 ],
        [125, 1063, 1259, 1460, 1707, 2026, 2551, 3051, 130, 380, 115, 1050 ],
        [130, 1118, 1320, 1528, 1783, 2111, 2653, 3168, 135, 400, 120, 1100 ],
        [135, 1173, 1382, 1595, 1859, 2197, 2756, 3285, 140, 420, 125, 1150 ],
        [140, 1230, 1445, 1666, 1937, 2284, 2860, 3404, 145, 440, 130, 1200 ],
        [145, 1288, 1509, 1736, 2015, 2372, 2965, 3523, 150, 460, 135, 1250 ],
        [150, 1347, 1575, 1808, 2096, 2462, 3072, 3645, 155, 480, 140, 1300 ],
        [155, 1407, 1641, 1881, 2176, 2552, 3179, 3766, 160, 500, 145, 1350 ],
        [160, 1468, 1709, 1956, 2259, 2644, 3288, 3890, 165, 520, 150, 1400 ],
        [165, 1530, 1778, 2030, 2342, 2737, 3398, 4014, 170, 540, 155, 1450 ],
        [170, 1594, 1848, 2108, 2427, 2831, 3509, 4140, 175, 560, 160, 1500 ],
        [175, 1658, 1919, 2185, 2512, 2926, 3621, 4266, 180, 580, 165, 1550 ],
        [180, 1725, 1992, 2264, 2600, 3023, 3735, 4394, 185, 600, 170, 1600 ],
        [185, 1791, 2065, 2343, 2687, 3120, 3849, 4523, 190, 620, 175, 1650 ],
        [190, 1860, 2140, 2425, 2777, 3219, 3965, 4654, 195, 640, 180, 1700 ],
        [195, 1929, 2215, 2507, 2866, 3318, 4081, 4784, 200, 660, 185, 1750 ],
        [200, 2000, 2293, 2591, 2958, 3419, 4200, 4917, 205, 680, 190, 1800 ],
        [210, 1350, 1556, 1967, 2350, 2830, 3645, 4391, false, 700, 195, 1850 ],
        [220, 1420, 1739, 2063, 2462, 2961, 3810, 4585, false, 720, 200, 1900 ],
        [230, 1490, 1822, 2159, 2574, 3092, 3975, 4779, false, 740, 205, 1950 ],
        [250, 1630, 1988, 2351, 2798, 3354, 4305, 5167, false, 760, 210, 2000 ],
        [300, 2030, 2453, 2881, 3408, 4059, 5180, 6187, false, 780, 215, 2050 ],
];

export const typeBaget = [
        {
                1624: ['01', '02(03)', '04', 12, 16, 18, 24, 32, 41, 44, '44(мат)', 45, 53, 305, 312, 'Голубой', 'Желтый', 'Зеленый', 'Зима', 'Красный', 'Оранжевый'],
        },
        {
                1626: ['04k', 57, 64, 312, 417, 418, 426, 427],
                1828: ['08', 10, 34, 41, 305, 355, 418, 429, 430],
        },
        {
                1430: ['01', '02', 24, 44, 305, 'кракэ'],
                1638: [121, 342, 350, 351, 353, 423, 428],
                2032: [18, 23, 141, 404, 406, 412, 418, 423],
                2230: ['02', 32, 121, 313, 341, 343, 419],
                2530: ['01', '08', 10, '011', 32, '32 (хром)', 34, 41, 121, 313, 412, 413, 414],
        },
        {
                2244: ['08', 10, 12, 18, 34, 44, 120, 313],
        },
        {
                2355: ['01', '011', 14, 27, 32, 141, 341, 342, 441, 442],
                2548: [141],
                3050: ['01', 16, 44, 406, 409],
                3664: ['08', 10, 32, 34, 120, 121, 313],
        },
        {
                2565: [27, 32, 343],
                3080: ['01', '011', 308, 412, 442],
        },
        {
                3580: ['01', '011', 32, 141, 341, 441, 442],
        },
];

export const imgBaget = [
        {
                1624: ['1624_01', '1624_02(03)', '1624_04', '1624_12', '1624_16', '1624_18', '1624_24', '1624_32', '1624_41', '1624_44', '1624_44мат', '1624_45', '1624_53', '1624_305', '1624_312', '1624_голубой', '1624_желтый', '1624_зеленый', '1624_зима', '1624_красный', '1624_оранжевый'],
        },
        {
                1626: ['1626_04k', '1626_57', '1626_64', '1626_312', '1626_417', '1626_418', '1626_426', '1626_427'],
                1828: ['1828_08', '1828_10', '1828_34', '1828_41', '1828_305', '1828_355', '1828_418', '1828_429', '1828_430'],
        },
        {
                1430: ['1430_01', '1430_02', '1430_24', '1430_44', '1430_305', '1430_кракэ'],
                1638: ['1638_121', '1638_342', '1638_350', '1638_351', '1638_353', '1638_423', '1638_428'],
                2032: ['2032_18', '2032_23', '2032_141', '2032_404', '2032_406', '2032_412', '2032_418', '2032_423'],
                2230: ['2230_02', '2230_32', '2230_121', '2230_313', '2230_341', '2230_343', '2230_419'],
                2530: ['2530_01', '2530_08', '2530_10', '2530_011', '2530_32', '2530_32_(хром)', '2530_34', '2530_41', '2530_121', '2530_313', '2530_412', '2530_413', '2530_414'],
        },
        {
                2244: ['2244_08', '2244_10', '2244_12', '2244_18', '2244_34', '2244_44', '2244_120', '2244_313'],
        },
        {
                2355: ['2355_01', '2355_011', '2355_14', '2355_27', '2355_32', '2355_141', '2355_341', '2355_342', '2355_441', '2355_442'],
                2548: ['2548_141'],
                3050: ['3050_01', '3050_16', '3050_44', '3050_406', '3050_409'],
                3664: ['3664_08', '3664_10', '3664_32', '3664_34', '3664_120', '3664_121', '3664_313'],
        },
        {
                2565: ['2565_27', '2565_32', '2565_343'],
                3080: ['3080_01', '3080_011', '3080_308', '3080_412', '3080_442'],
        },
        {
                3580: ['3580_01', '3580_011', '3580_32', '3580_141', '3580_341', '3580_441', '3580_442'],
        },
];