import React from 'react';

const Contacts = () => {
    return (
        <>
        <div className="col-sm-9" id="contentIdEmpty">
            <h1 > 404 - страница не найдена!</h1>
            <a href='/'>Перейдите на главную!</a>
          </div>
        </>
    );
};

export default Contacts;