import React, { useEffect } from "react";




// Таблица заказанных товаров
const ThreeDdetails = () => {






    // #########################################################################################

    return (
        <>
            <h1>3D Печать:</h1>
            <iframe className="youtube-video" src="https://www.youtube.com/embed/reKcniYdh9g?si=62lsjCD-s98QICQR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </>
    );
};

export default ThreeDdetails;

